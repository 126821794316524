import {ref} from 'vue';
import {defineStore} from 'pinia';
import {ErrorInterface} from "~/types/interfaces/ErrorInterface";
import {useCSRF} from "~/stores/csrf";
import {useAPI} from "~/composables/useAPI";
import {Plan} from "~/types/interfaces/entitites/PlanInterface";

export const usePlansClientStore = defineStore('plansClient', () => {
    //https://pinia.vuejs.org/core-concepts/#setup-stores
    //ref()s become state properties
    const error = ref<ErrorInterface | null>(null);
    const plan = ref<Plan>({} as Plan);
    const plans = ref<Plan[]>([]);
    const useApi = useAPI();
    const csrfStore = useCSRF();

    // computed()s become getters
    const plansList = computed(() => plans.value)
    const currentPlan = computed(() => plan.value)

    //function()s become actions
    function setPlan(planData: Plan) {
        plan.value = planData;
    }

    function setPlans(plansData: [Plan]) {
        plans.value = plansData;
    }

    function setError(errorData: any) {
        error.value = errorData;
    }

    function resetErrors() {
        error.value = null;
    }

    async function list() {
        await useApi.apiFetch('/client/plan/list', {
            method: 'GET',
        }).then(({data}) => {
            try {
                setPlans(data);
            } catch (e) {
                console.log("exc", e);
            }

        }).catch(({response}) => {
            if (response) {
                setError(response._data);
            } else {
                setError({
                        application_code: '',
                        data: {},
                        message: 'No hay conexión',
                        status: 'Error',
                    }
                );
            }
        });
    }

    async function listByProvince(provinceHash: string) {
        await useApi.apiFetch(`/client/plan/list-by-province/${provinceHash}`, {
            method: 'GET',
        }).then(({data}) => {
            try {
                setPlans(data);
            } catch (e) {
                console.log("exc", e);
            }

        }).catch(({response}) => {
            if (response) {
                setError(response._data);
            } else {
                setError({
                        application_code: '',
                        data: {},
                        message: 'No hay conexión',
                        status: 'Error',
                    }
                );
            }
        });
    }


    return {
        error,
        plansList,
        currentPlan,
        setPlan,
        resetErrors,
        list,
        listByProvince,
    };
});
